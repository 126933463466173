<template>
    <div class="ApprovalTag">
      <!-- 任务审批 -->
      <van-nav-bar
        :title="'任务审批'"
        left-text
        left-arrow
        @click-left="onClickLeft"
        :fixed="true"
      />
      <div class="content">
        <van-search
          v-model="searchVal"
          shape="round"
          :placeholder="'搜索'"
          @search="onSearch"
        />
        <van-tabs
          v-model="activeName"
          color="#2B8DF0"
          title-active-color="#2B8DF0"
          @change="changeAct"
        >
          <van-tab :title="'待审批'+sureNum" name="Sure"></van-tab>
          <van-tab :title="'待提交'+submitNum" name="Submit"></van-tab>
          <van-tab :title="'已审批'+checkNum" name="Check"></van-tab>
          <van-tab :title="'不同意'+noNum" name="No"></van-tab>
        </van-tabs>
  
        <van-pull-refresh
          :class="activeName == 'Sure' ? 'Check' : ''"
          v-model="isLoadingAwait"
          @refresh="onRefreshAwait"
        >
          <van-list
            v-model="loadingAwait"
            :finished="finishedAwait"
            :finished-text="$t('module.noMore')"
            @load="onLoadAwait"
          >
            <div
              class="zb_group"
              v-for="(item, index) in assessList"
              :key="index"
            >
              <div class="zb_group">
                <van-cell
                  class="ass_cell"
                  size="large"
                  :title="item.listmemo"
                  @click="goToApply(item)"
                >
                <template slot="label">
                  <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
                    <span style="color: #e02e2e;">{{ item.listtype }}</span>
                    <span style="color: #e02e2e;">{{ item.crdate }}</span>
                  </div>
                </template>
                </van-cell>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </template>
  
  <script>
  import { Toast } from 'vant'
  import {
    getTmForCheckList,
    getTmForPostList,
    getTmHasCheckList,
    getTmNotAgreeList
  } from '@api/wxjj.js'
  export default {
    data() {
      const userInfo = localStorage.userInfo
        ? JSON.parse(localStorage.userInfo)
        : {}
      return {
        userInfo,
        activeName: 'Sure',
        isLoadingAwait: false,
        loadingAwait: false,
        finishedAwait: false,
        assessList: [],
        sureList: [],
        submitList:[],
        checkList: [],
        noList: [],
        searchVal: '',
        sureNum: '',
        noNum: '',
        submitNum: '',
        checkNum: ''
      }
    },
    watch: {
      searchVal(n) {
        this.onSearch()
      }
    },
    created() {},
    methods: {
      onSearch() {
        if (this.searchVal) {
          this.assessList = this.assessList.filter(
            item => item.listmemo.indexOf(this.searchVal) > -1
          )
        } else {
          this.getList()
        }
      },
      onClickLeft() {
        this.$router.push('/home')
      },
      // 9.1.获取待审批的360考核方案列表
      getList() {
        let _this = this
        getTmForCheckList({ username: this.userInfo.username }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            _this.sureNum = ''
            // 无数据
          } else {
            _this.sureNum = '(' + res.data.length + ')'
            _this.sureList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
  
          if (_this.activeName == 'Sure')
            _this.assessList = JSON.parse(JSON.stringify(_this.sureList))
        })
        getTmForPostList({ username: this.userInfo.username }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            _this.submitNum = ''
            // 无数据
          } else {
            _this.submitNum = '(' + res.data.length + ')'
            _this.submitList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
          if (_this.activeName == 'Submit')
            _this.assessList = JSON.parse(JSON.stringify(_this.submitList))
        })
        getTmHasCheckList({ username: this.userInfo.username }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            _this.checkNum = ''
            // 无数据
          } else {
            _this.checkNum = '(' + res.data.length + ')'
            _this.checkList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
          if (_this.activeName == 'Check')
            _this.assessList = JSON.parse(JSON.stringify(_this.checkList))
        })

        getTmNotAgreeList({ username: this.userInfo.username }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            _this.noNum = ''
            // 无数据
          } else {
            _this.noNum = '(' + res.data.length + ')'
            _this.noList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
          if (_this.activeName == 'No')
            _this.assessList = JSON.parse(JSON.stringify(_this.noList))
        })
      },
      onLoadAwait() {
        // 异步更新数据
        if (this.isLoadingAwait) {
          this.assessList = []
          this.isLoadingAwait = false
        }
        this.getList()
      },
      onRefreshAwait() {
        this.assessList = []
        this.finishedAwait = false
        this.loadingAwait = true
        this.onLoadAwait()
        // Toast('刷新成功')
      },
      // tab切换事件
      changeAct(name, title) {
        this.onRefreshAwait()
      },
      // 发起指标申请
      goToApply(item) {
        // type：1待审批 2待提交 3已审批 4不同意
        if (this.activeName == 'Sure') {
          if(item.moduleno == 816){
            this.$router.push({
                path:'/zsCheckIn',
                query: { type:1,autoid:item.autoid }
            })
          }else{
            this.$router.push({
                path:'/zzCheckIn',
                query: { type:1,autoid:item.autoid }
            })
          }
        } else if (this.activeName == 'Submit') {
            if(item.moduleno == 816){
            this.$router.push({
                path:'/zsCheckIn',
                query: { type:2,autoid:item.autoid }
            })
          }else{
            this.$router.push({
                path:'/zzCheckIn',
                query: { type:2,autoid:item.autoid }
            })
          }
        } else if (this.activeName == 'Check') {
            if(item.moduleno == 816){
                this.$router.push({
                    path:'/zsCheckIn',
                    query: { type:3,autoid:item.autoid }
                })
            }else{
                this.$router.push({
                    path:'/zzCheckIn',
                    query: { type:3,autoid:item.autoid }
                })
            }
        } else if (this.activeName == 'No') {
            if(item.moduleno == 816){
                this.$router.push({
                    path:'/zsCheckIn',
                    query: { type:4,autoid:item.autoid }
                })
            }else{
                this.$router.push({
                    path:'/zzCheckIn',
                    query: { type:4,autoid:item.autoid }
                })
            }
        }
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .ApprovalTag {
    height: 100vh;
    overflow: hidden;
    .van-nav-bar {
      background: #2b8df0;
  
      /deep/ .van-nav-bar__left {
        .van-icon {
          color: #fff;
        }
      }
      /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 36px;
        font-family: Source Han Sans CN;
      }
    }
    .content {
      // overflow: auto;
      margin-top: 1.22667rem;
      height: calc(100% - 92px);
      /deep/.van-tabs {
        .van-tab {
          .van-tab__text {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: bold;
          }
        }
      }
      .van-pull-refresh {
        margin-top: 30px;
        overflow: auto;
        height: calc(100% - 120px);
      }
      .KPITagRefresh {
        margin-top: 30px;
        overflow: auto;
        height: calc(100% - 220px);
      }
  
      .zb_group {
        // .van-cell::after {
        //   position: absolute;
        //   box-sizing: border-box;
        //   content: " ";
        //   pointer-events: none;
        //   right: 0.42667rem;
        //   bottom: 0;
        //   left: 0.42667rem;
        //   border-bottom: 2px solid #ebedf0;
        //   -webkit-transform: scaleY(0.5);
        //   transform: scaleY(0.5);
        // }
        .ass_cell {
          margin: 10px 0;
          .van-cell__title {
            flex: 3;
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 54px;
            color: #333333;
          }
        }
      }
    }
  }
  </style>
  